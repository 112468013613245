import React from 'react';
import {Trans, t} from '@lingui/macro';
import PropTypes from 'prop-types';
import { Assignments, Task } from '../../constants/propTypesDefinitions';
import Table from '../general/Table';
import { MONTH_NAMES } from '../../constants/months';
import AssignmentCell from './AssignmentCell';
import { formatApiMonth } from '../../lib/formatting';
import Tooltip from '../general/Tooltip';
import TaskEdit from './TaskEdit';
import { GLOBAL_DATA } from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import TaskRemove from './TaskRemove';
import { Checkbox, notification } from 'antd';
import { getInputOnChangeEventChecked } from '../../lib/project';
import { concatUnique } from '../../lib/array';
import AssignmentAdd from './AssignmentAdd';
import Price from '../general/Price';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../constants/Rights';
import { createFetchWageEdit } from '../../backend/apiCalls';
import InputPrice from '../project/InputPrice';
import { merge } from '../../lib/object';

/**
 * @dusan
 */

 class AssignmentsTable extends React.PureComponent {
    static propTypes = {
        assignments: Assignments.isRequired,
        wages: PropTypes.array,
        year: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
        reload: PropTypes.func.isRequired,
        selectedTaskId: PropTypes.number,
        selectedAssignmentIds: PropTypes.arrayOf(PropTypes.number),
        onTaskSelect: PropTypes.func.isRequired,
        onAssignmentSelect: PropTypes.func.isRequired,
        pin: PropTypes.string.isRequired,
    };

    

    render() {
        const {assignments, wages, pin, year, userId, reload, selectedTaskId, selectedAssignmentIds, onTaskSelect, onAssignmentSelect} = this.props;
        const assignmentsChecked = assignments != null && Array.isArray(assignments) ? assignments : [];
        let taskIds = assignmentsChecked.map(a => a.id_task).filter((v, i, a) => a.indexOf(v) === i);

        const rows = taskIds.map(taskId => {
            return assignmentsChecked.filter(a => a.id_task == taskId);
        });

        const allAssignmentIds = assignmentsChecked.map(a => a.id);
        const isAllSelected = allAssignmentIds.filter(aId => selectedAssignmentIds.includes(aId) == false).length == 0;

        let colDefs = [
            {
                headerCell: <div>
                    <div><Trans>Úlohy</Trans></div>
                    <div className="font-italic">
                        <Checkbox
                            checked={isAllSelected}
                            onChange={(e) => {
                                if(getInputOnChangeEventChecked(e))
                                {
                                    // select all
                                    onAssignmentSelect(allAssignmentIds);
                                }
                                else
                                {
                                    // clear selection
                                    onAssignmentSelect([]);
                                }
                            }}
                        >
                            <Trans>vybrať všetko</Trans>
                        </Checkbox>
                    </div>
                </div>,
                class: 'assignments-table-col-task',
                colspan: 2,
            }
        ];

        for(let m = 1; m <= 12; m++)
        {
            const at = formatApiMonth(year, m);
            const colAsignmentIds = assignmentsChecked.filter(a => a.at == at).map(a => a.id);
            const isColSelected = colAsignmentIds.filter(aId => selectedAssignmentIds.includes(aId) == false).length == 0;

            colDefs.push({
                headerCell: <div>
                    <div>{MONTH_NAMES[m]}</div>
                    <div className="font-italic">
                        <Checkbox
                            checked={isColSelected}
                            onChange={(e) => {
                                if(getInputOnChangeEventChecked(e))
                                {
                                    // select all
                                    onAssignmentSelect(concatUnique(selectedAssignmentIds, colAsignmentIds));
                                }
                                else
                                {
                                    // clear selection
                                    onAssignmentSelect(selectedAssignmentIds.filter(aId => colAsignmentIds.includes(aId) == false));
                                }
                            }}
                        >
                            <Trans>vybrať</Trans>
                        </Checkbox>
                    </div>
                </div>,
                class: 'assignments-table-col-month'
            });
        }

        return <div>
            <Table
                className="assignments-table"
                BodyRow={AssignmentsRowWrapped}
                data={rows}
                year={year}
                userId={userId}
                pin={pin}
                selectedTaskId={selectedTaskId}
                onTaskSelect={onTaskSelect}
                selectedAssignmentIds={selectedAssignmentIds}
                onAssignmentSelect={onAssignmentSelect}
                reload={reload}
                notScrollable={true}
                noTBody={true}
                colDefs={colDefs}
                footerRow={
                    <SummaryRowWrapped
                        assignments={assignments}
                        wages={wages}
                        year={year}
                        userId={userId}
                        reload={reload}
                        pin={pin}
                        onTaskSelect={onTaskSelect}
                    />
                }
            />
        </div>;
    }
 };

export default AssignmentsTable;

class AssignmentsRow extends React.PureComponent {
    static propTypes = {
        data: Assignments.isRequired,
        pin: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
        reload: PropTypes.func.isRequired,
        selectedTaskId: PropTypes.number,
        onTaskSelect: PropTypes.func.isRequired,
        selectedAssignmentIds: PropTypes.arrayOf(PropTypes.number),
        onAssignmentSelect: PropTypes.func.isRequired,
        [GLOBAL_DATA.TASKS]: PropTypes.arrayOf(Task.isRequired).isRequired,
    };

    render() {
        const {data, pin, userId, year, reload, selectedTaskId, [GLOBAL_DATA.TASKS]: tasks, [GLOBAL_DATA.RELOAD_DATA]: reloadGlobals,
            selectedAssignmentIds, onTaskSelect, onAssignmentSelect} = this.props;
        let monthKeys = [];
        for(let m = 1; m <= 12; m++)
            monthKeys.push(formatApiMonth(year, m));

        const assignments = monthKeys.map(m => {
            return data.find(a => a.at == m);
        });


        const taskId = data != null && data.length > 0 ? data[0].id_task : null;
        const task = taskId != null ? tasks.find(t => t.id == taskId) : null;
        const taskName = task != null ? task.name : null;
        const taskDescription = task != null ? task.description : null;
        const allSelected = data != null && selectedAssignmentIds != null && data.filter(d => selectedAssignmentIds.includes(d.id) == false).length == 0;

        let dataKeys = [];
        let labels = [];

        if(task != null && task.is_binary)
        {
            dataKeys = [
                'result',
                'bonus',
                'saldo',
                'notices',
                'actions',
            ];

            labels = [
                <Trans>Výsledok:</Trans>,
                <Trans>Odmena:</Trans>,
                <Trans>Zostatok:</Trans>,
                <Trans>Poznámky:</Trans>,
                <Trans>Akcie:</Trans>,
            ];
        }
        else
        {
            dataKeys = [
                'target',
                'result',
                'bonus',
                'saldo',
                'notices',
                'actions',
            ];

            labels = [
                <Trans>Plán:</Trans>,
                <Trans>Výsledok:</Trans>,
                <Trans>Odmena:</Trans>,
                <Trans>Zostatok:</Trans>,
                <Trans>Poznámky:</Trans>,
                <Trans>Akcie:</Trans>,
            ];
        }

        return <tbody>
            { dataKeys.map((dataKey, idx) => {
                return <tr>
                    {idx == 0 ? 
                        <td rowSpan={dataKeys.length} onClick={() => { onTaskSelect(taskId) }}>
                            <div className="font-weight-bold">
                                <Tooltip title={taskDescription}>
                                    {taskName}
                                </Tooltip>
                            </div>
                            <div className="font-italic">
                                <Checkbox
                                    checked={allSelected}
                                    onChange={(e) => {
                                        const allIds = data != null ? data.map(a => a.id) : [];
                                        if(getInputOnChangeEventChecked(e))
                                        {
                                            // select all
                                            onAssignmentSelect(concatUnique(selectedAssignmentIds, allIds));
                                        }
                                        else
                                        {
                                            // clear selection
                                            onAssignmentSelect(selectedAssignmentIds.filter(aId => !allIds.includes(aId)));
                                        }
                                    }}
                                >
                                    <Trans>vybrať</Trans>
                                </Checkbox>
                            </div>
                            { taskId == selectedTaskId && task != null ? 
                                <div className="mt-2 d-flex align-items-center">
                                    <TaskEdit
                                        task={task}
                                        size="small"
                                        onFinish={() => {
                                            reload();
                                            reloadGlobals([GLOBAL_DATA.TASKS]);
                                            
                                        }}
                                    />
                                    <TaskRemove
                                       task={task}
                                       size="small"
                                       onFinish={() => {
                                        reload();
                                        reloadGlobals([GLOBAL_DATA.TASKS]);
                                    }}
                                   /> 
                                </div> : null
                            }
                        </td> : null
                    }
                    <td className="text-right" style={{borderLeft: 'none'}}>
                        {labels[idx]}
                    </td>
                    { assignments.map((a, key) => {
                        if(a != null) 
                        {
                            return <td key={key} className="text-right">
                                <AssignmentCell
                                    data={a}
                                    pin={pin}
                                    dataKey={dataKey}
                                    reload={reload}
                                    onSelect={onAssignmentSelect}
                                    selectedAssignmentIds={selectedAssignmentIds}
                                />
                            </td>;
                        }
                        else if(idx == 0)
                        {
                            return <td key={key} style={{textAlign: 'center', verticalAlign: 'middle'}} rowspan={dataKeys.length}>
                                    <AssignmentAdd
                                        size="small"
                                        userId={userId}
                                        taskId={taskId}
                                        year={year}
                                        month={key + 1}
                                        pin={pin}
                                        onFinish={reload}
                                    />
                                </td>;
                        }
                        else
                        {
                            return null;
                        }
                })}
                </tr>;
            })}
        </tbody>;
    }
};

const AssignmentsRowWrapped = withDataHOC([GLOBAL_DATA.TASKS, GLOBAL_DATA.RELOAD_DATA])(AssignmentsRow);

class SummaryRow extends React.PureComponent {
    static propTypes = {
        assignments: Assignments.isRequired,
        wages: PropTypes.array.isRequired,
        userId: PropTypes.number.isRequired,
        pin: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        onTaskSelect: PropTypes.func.isRequired,
        reload: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    editWage = (queryParams) => {
        const {userId, year, reload, pin} = this.props;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchWageEdit(),
            merge({id_user: userId, yr: year, pin: pin}, queryParams),
            null,
            reload,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    editNetto = (month) => (val) => {
        this.editWage({mon: month, netto: val});
    };

    editBrutto = (month) => (val) => {
        this.editWage({mon: month, brutto: val});
    };

    editTotal = (month) => (val) => {
        this.editWage({mon: month, total: val});
    };

    render() {
        const {assignments, wages, year, onTaskSelect} = this.props;
        let monthKeys = [];
        for(let m = 1; m <= 12; m++)
            monthKeys.push(formatApiMonth(year, m));

        return <React.Fragment>
            <tr onClick={() => {onTaskSelect(null)}}>
                <td colSpan={2} className="font-weigth-bold"><Trans>Odmena spolu:</Trans></td>
                { monthKeys.map((m, idx) => {
                    const column = assignments.filter(a => a.at == m);
                    const sumBonus = column.reduce((s, a) => {
                        return (s + (Number.isNaN(Number(a.bonus)) ? 0.0 : Number(a.bonus)));
                    }, 0.0);
                    return <td key={idx} className="text-right font-weight-bold">
                        <Price price={sumBonus}/>
                    </td>;
                })}
            </tr>
            <tr>
                <td colSpan={2}><Trans>Hrubá mzda:</Trans></td>
                { monthKeys.map((m, idx) => {
                    const wObj = wages.find(w => w.at == m);
                    const wage = wObj != null ? wObj : {};

                    return <td key={idx} className="text-right">
                        <RightsWrapper from={RIGHTS.MANAGER}>
                            <InputPrice
                                value={wage.brutto}
                                size="small"
                                className="text-right"
                                onChange={this.editBrutto(idx+1)}
                            />
                        </RightsWrapper>
                        <RightsWrapper to={RIGHTS.SUPERVISOR}>
                            <Price price={wage.brutto}/>
                        </RightsWrapper>
                    </td>;
                })}
            </tr>
            <tr>
                <td colSpan={2}><Trans>Čistá mzda:</Trans></td>
                { monthKeys.map((m, idx) => {
                    const wObj = wages.find(w => w.at == m);
                    const wage = wObj != null ? wObj : {};

                    return <td key={idx} className="text-right">
                        <RightsWrapper from={RIGHTS.MANAGER}>
                            <InputPrice
                                value={wage.netto}
                                size="small"
                                className="text-right"
                                onChange={this.editNetto(idx+1)}
                            />
                        </RightsWrapper>
                        <RightsWrapper to={RIGHTS.SUPERVISOR}>
                            <Price price={wage.netto}/>
                        </RightsWrapper>
                    </td>;
                })}
            </tr>
            <tr>
                <td colSpan={2} className="font-weigth-bold"><Trans>Celk. cena práce:</Trans></td>
                { monthKeys.map((m, idx) => {
                    const wObj = wages.find(w => w.at == m);
                    const wage = wObj != null ? wObj : {};

                    return <td key={idx} className="text-right font-weigth-bold">
                        <RightsWrapper from={RIGHTS.MANAGER}>
                            <InputPrice
                                value={wage.total}
                                size="small"
                                className="text-right font-weight-bold"
                                onChange={this.editTotal(idx+1)}
                            />
                        </RightsWrapper>
                        <RightsWrapper to={RIGHTS.SUPERVISOR}>
                            <Price price={wage.total}/>
                        </RightsWrapper>
                    </td>;
                })}
            </tr>
        </React.Fragment>;

    }
};

const SummaryRowWrapped = withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(SummaryRow);