import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {createSetQueryParamValue, getQueryParamNumber} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {UserInfo} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { Icon, InputNumber } from 'antd';
import EmployeesSelect from '../project/EmployeesSelect';
import AssignmentsTable from './AssignmentsTable';
import AssignmentAdd from './AssignmentAdd';
import AssignmentEdit from './AssignmentEdit';
import AssignmentRemove from './AssignmentRemove';
import AssignmentReload from './AssignmentReload';
import AssignmentSign from './AssignmentSign';
import deepEqual from 'deep-equal';
import { createFetchAssignmentAll, createFetchWageAll, createFetchWagesSummary } from '../../backend/apiCalls';
import pinProtectedPageHOC from '../fetch/pinProtectedPageHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { RIGHTS } from '../../constants/Rights';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import MonthSelect from '../general/MonthSelect';
import NavigateButton from '../general/NavigateButton';
import WagesTable from './WagesTable';

/**
 * @dusan
 */

class AssignmentsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
        pin: PropTypes.string.isRequired,
        onPinFailure: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedTaskId: null,
            selectedAssignmentIds: [],
            assignments: [],
            wages: [],
            wagesSummary: null,
        };
    }

    componentDidMount() {
        this.fetchAssignments();
    }

    getFetchParams = (props) => {
        const {location, pin, [GLOBAL_DATA.ACCOUNT_INFO]: account, [SESSION_ATTRIBUTES.RIGHTS]: rights} = props;

        const year = getQueryParamNumber(location, QUERY_PARAMS.ASSIGNMENTS_YEAR);
        const month = getQueryParamNumber(location, QUERY_PARAMS.ASSIGNMENTS_MONTH);
        const userId = getQueryParamNumber(location, QUERY_PARAMS.ASSIGNMENTS_ID_USER);
        const now = new Date();

        // for managers, allow to show summary table (userId == null), otherwise only own
        const userIdChecked = userId != null ? userId : (rights >= RIGHTS.MANAGER ? undefined : account.id);
        const yrChecked = year != null ? year : now.getFullYear();
        const monthChecked = userIdChecked != null ? undefined : (
            month != null ? month : now.getMonth()+1
        );

        return {
            yr: yrChecked,
            mon: monthChecked,
            id_user: userIdChecked,
            pin: pin
        };
    }
    
    componentDidUpdate(prevProps) {
        const params = this.getFetchParams(this.props);
        const prevParams = this.getFetchParams(prevProps);

        if(!deepEqual(params, prevParams))
            this.fetchAssignments();
    }

    fetchAssignments = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, onPinFailure} = this.props;
        const params = this.getFetchParams(this.props);
        
        if(params.pin != null && params.yr != null)
        {
            if(params.id_user != null)
            {
                fetchHandler(
                    createFetchAssignmentAll(),
                    params,
                    (result) => {
                        this.setState({
                            assignments: result, 
                            wagesSummary: null, 
                            selectedAssignmentIds: []
                        });
                    },
                    null,
                    onPinFailure
                )

                fetchHandler(
                    createFetchWageAll(),
                    params,
                    (result) => {
                        this.setState({wages: result});
                    },
                    null,
                    onPinFailure
                )
            }
            else
            {
                fetchHandler(
                    createFetchWagesSummary(),
                    params,
                    (result) => {
                        this.setState({wagesSummary: result});
                    },
                    null,
                    onPinFailure
                )
            }
        }
        else
        {
            this.setState({
                wagesSummary: null, 
                wages: [],
                assignments: [],
                selectedAssignmentIds: []
            });
        }
    }

    onTaskSelect = (taskId) => {
        this.setState({selectedTaskId: taskId});
    }

    onAssignmentSelect = (assignId) => {
        this.setState({
            selectedTaskId: null,
            selectedAssignmentIds: assignId
        });
    }

    render() {
        const {location, pin, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {assignments, selectedAssignmentIds, selectedTaskId, wages, wagesSummary} = this.state;
        const params = this.getFetchParams(this.props);
        
        const selectedAssignments = assignments != null && selectedAssignmentIds != null ? 
            assignments.filter(a => selectedAssignmentIds.includes(a.id)) : [];

        return <React.Fragment>
            <Helmet
                title={t`Úlohy a odmeny`}
            /> 
            <div className="p-3 full-size-height overflow-y-auto">
                <div className="pb-2 d-flex justify-content-between">
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <span className="mr-2"><Trans>Rok:</Trans></span>
                            <InputNumber
                                value={params.yr}
                                step={1}
                                min={2000}
                                onChange={createSetQueryParamValue(location, QUERY_PARAMS.ASSIGNMENTS_YEAR)}
                                className="year-input"
                            />
                        </div>
                        { params.id_user == null ?
                            <div className="d-flex align-items-center">
                                <span className="ml-3 mr-2"><Trans>Mesiac:</Trans></span>
                                <MonthSelect
                                    className="assignments-month-select"
                                    value={params.mon}
                                    onChange={createSetQueryParamValue(location, QUERY_PARAMS.ASSIGNMENTS_MONTH)}
                                />
                            </div>
                            : null
                        }
                        <div className="d-flex align-items-center">
                            <span className="ml-3 mr-2"><Trans>Zamestnanec:</Trans></span>
                            <EmployeesSelect
                                className="assignments-user-select"
                                value={params.id_user}
                                onChange={createSetQueryParamValue(location, QUERY_PARAMS.ASSIGNMENTS_ID_USER)}
                                allowClear={rights >= RIGHTS.MANAGER}
                                placeholder="všetci"
                            />
                            {params.id_user != null ?
                                <NavigateButton
                                    to={ROUTES.CUSTOMERS_USER_DETAILS}
                                    queryParams={{[QUERY_PARAMS.USER_DETAILS_ID_USER]: params.id_user}}
                                    tooltipTitle={<Trans>Zobraziť detail používateľa</Trans>}
                                >
                                    <Icon type="bars"/>
                                </NavigateButton>
                                : null
                            }
                        </div>
                    </div>
                    <div className="d-flex">
                        <AssignmentAdd
                            userId={params.id_user}
                            year={params.yr}
                            pin={pin}
                            assignments={selectedAssignments}
                            onFinish={this.fetchAssignments}
                        />
                        <AssignmentReload
                            assignments={selectedAssignments}
                            pin={pin}
                            onFinish={this.fetchAssignments}
                        />
                        <AssignmentEdit
                            assignments={selectedAssignments}
                            pin={pin}
                            onFinish={this.fetchAssignments}
                        />
                        <AssignmentSign
                            assignments={selectedAssignments}
                            pin={pin}
                            onFinish={this.fetchAssignments}
                        />
                        <AssignmentRemove
                            assignments={selectedAssignments}
                            pin={pin}
                            onFinish={this.fetchAssignments}
                        />
                    </div>
                </div>
                {params.id_user != null ? 
                    <AssignmentsTable
                        assignments={assignments}
                        wages={wages}
                        pin={pin}
                        userId={params.id_user}
                        year={params.yr}
                        reload={this.fetchAssignments}
                        selectedTaskId={selectedTaskId}
                        selectedAssignmentIds={selectedAssignmentIds}
                        onTaskSelect={this.onTaskSelect}
                        onAssignmentSelect={this.onAssignmentSelect}
                    />
                    :
                    <WagesTable
                        wages={wagesSummary}
                        pin={pin}
                        year={params.yr}
                        month={params.mon}
                        reload={this.fetchAssignments}
                    />
                }
            </div>
        </React.Fragment>;
    }

}

export default pinProtectedPageHOC(
    locationHOC(
        withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
            withDataHOC([GLOBAL_DATA.FETCH_HANDLER, GLOBAL_DATA.ACCOUNT_INFO])(AssignmentsPage)
        )
    )
);