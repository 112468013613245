import React from 'react';
import PropTypes from 'prop-types';
import {Button, Upload, notification} from 'antd';
import {Trans} from '@lingui/macro';
import Tooltip from '../general/Tooltip';
import { IMPORT_TARGET } from '../../constants/apiEndpoints';
import { getDomain } from '../../lib/url';
/**
 * @dusan
 */

class WageImport extends React.PureComponent {
    static propTypes = {
        year: PropTypes.number.isRequired,
        month: PropTypes.number,
        pin: PropTypes.string.isRequired,
        onFinish: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onUpload = (file) => {
        const {year, month, pin, onFinish} = this.props;

        this.setState( { loading: true });

        const formData = new FormData();
        formData.append('file',file);
        formData.append('import_type', 'wage/import');
        formData.append('yr', year);
        formData.append('mon', month);
        formData.append('pin', pin);

        fetch(getDomain()+IMPORT_TARGET, {method: 'POST',  body: formData, credentials: 'include'})
        .then((response) => { 
            return response.json()
            .then((json) => {
            	this.setState({loading: false});

                if(json.result != null)
                	notification['success']({ 
						message: json.result, 
						duration: 3, 
					}); 
                
                if(json.error != null)
                	notification['error']({ 
						message: json.error.message,
						duration: 0,
					});

                if(onFinish != null)
                    onFinish();
            });
        })
        .catch((ex) => {
        	this.setState({
	            file: null, 
	            loading: false,
	        });

            notification['error']({ 
				message: ex.toString(),
				duration: 0,
			});
        });
    };

    render() {
        const {year, month, pin, onFinish, ...props} = this.props;
        const {loading} = this.state;
        return <Upload 
            accept='.xlsx'
            showUploadList={false}
            beforeUpload={this.onUpload}
        >
            <Tooltip title={<Trans>Import miezd</Trans>}>
                <Button loading={loading} icon="upload" {...props}/>
            </Tooltip>
        </Upload>
    }

}

export default WageImport;