import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import TaskAdd from './TaskAdd';
import TaskEdit from './TaskEdit';
import TaskRemove from './TaskRemove';

/**
 * @dusan
 */

class TaskSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType(PropTypes.number, PropTypes.arrayOf(PropTypes.number)),
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    reloadTasks = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.TASKS]);
    }

    render() {
        const {[GLOBAL_DATA.RELOAD_DATA]: reload, [GLOBAL_DATA.TASKS]: tasks, value, disabled, ...props} = this.props;
        const firstValue = Array.isArray(value) ? (value.length == 1 ? value[0] : null) : value;
        const selectedTask = tasks != null && firstValue != null ? tasks.find(t => t.id == firstValue) : null;

        return <div className="d-flex">
            <Select
                {...props}
                value={value}
                disabled={disabled}
                options={tasks.map(t => {
                    return {
                        label: t.name,
                        value: t.id,
                    }
                })}
            />
            <div className="d-flex align-items-center">
                <TaskAdd
                    task={selectedTask}
                    disabled={disabled}
                    onFinish={this.reloadTasks}
                />
                { selectedTask != null ? 
                    <TaskEdit
                        task={selectedTask}
                        disabled={disabled}
                        onFinish={this.reloadTasks}
                    /> : null
                }
                { selectedTask != null ? 
                    <TaskRemove
                        task={selectedTask}
                        disabled={disabled}
                        onFinish={this.reloadTasks}
                    /> : null
                }
            </div>
        </div>;
    }
}

export default withDataHOC([GLOBAL_DATA.TASKS, GLOBAL_DATA.RELOAD_DATA])(TaskSelect);