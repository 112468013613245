import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Input, Radio} from 'antd';
import {createFetchTaskAdd, createFetchTaskEdit} from '../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../constants/form';
import { Task } from '../../constants/propTypesDefinitions';
import DashboardItemsSelect from '../project/DashboardItemsSelect';
import TaskSelect from './TaskSelect';
import InputQuantity from '../project/InputQuantity';
const FormItem = Form.Item;
const TextArea = Input.TextArea;

/**
 * @dusan
 */

class TaskForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            task: Task,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, task = {}} = values;
                const penalizedLevel = formValues.critical_percents != null ? Number(formValues.critical_percents) / 100.0 : null;
                const fetchFunction = isEdit ? createFetchTaskEdit() : createFetchTaskAdd();
                const checkedValues = isEdit ?
                    merge(formValues, {id: task.id, critical_level: penalizedLevel}) 
                    : merge(formValues, {critical_level: penalizedLevel});

                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, task}, onClose} = this.props;
        const taskChecked = task != null ? task : {};
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov úlohy</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: taskChecked.name})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('description', {initialValue: taskChecked.description})(
                    <TextArea/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Sumárna kategória</Trans>}
            >
                {getFieldDecorator('category', {initialValue: taskChecked.category})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Štatistika</Trans>}
            >
                {getFieldDecorator('variable', {initialValue: taskChecked.variable })(
                    <DashboardItemsSelect
                        isMulti={false}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ vyhodnotenia</Trans>}
            >
                {getFieldDecorator('is_binary', {initialValue: taskChecked.is_binary !== undefined ? taskChecked.is_binary : 1 })(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Zaškrtávacie políčko</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Hodnota</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zamestnanec môže editovať</Trans>}
            >
                {getFieldDecorator('is_editable', {initialValue: taskChecked.is_editable !== undefined ? taskChecked.is_editable : 1 })(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Automaticky prideliť odmenu</Trans>}
            >
                {getFieldDecorator('auto_bonus', {initialValue: taskChecked.auto_bonus !== undefined ? taskChecked.auto_bonus : 0 })(
                    <Radio.Group>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Kritické nesplenie úlohy pod</Trans>}
            >
                {getFieldDecorator('critical_percents', {initialValue: taskChecked.critical_level != null ? Number(taskChecked.critical_level) * 100 : null})(
                    <InputQuantity
                        unit="%"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Blokovať odmenu za</Trans>}
            >
                {getFieldDecorator('id_penalized_task', {initialValue: taskChecked.penalized_tasks != null ? taskChecked.penalized_tasks.map(t => t.id_penalized_task) : null})(
                    <TaskSelect
                        isMulti={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(TaskForm);