import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../fetch/generalFailedPC';
import FormModal from '../fetch/FormModal';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {Assignments, Task} from '../../constants/propTypesDefinitions';
import {createFetchAssignmentRemove} from '../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class AssignmentRemove extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        assignments: Assignments.isRequired,
        pin: PropTypes.string.isRequired,
    };

    render() {
        const {assignments, onFinish, pin} = this.props;
        const assignmentIds = assignments != null ? assignments.map(a => a.id) : [];
        const disabled = assignmentIds.length == 0;
        return <FormModal
            openNode={
                <Button className="mx-1" type="danger" icon="close" disabled={disabled}>
                    <span className="ml-2"><Trans>Zrušiť úlohy</Trans></span>
                </Button>
            }
            disabled={disabled}
            values={{}}
            onFinishSuccessful={onFinish}
            title={<Trans>Zrušenie úloh</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete zrušiť vybrané úlohy?</Trans>,
                createFetchAssignmentRemove(assignmentIds, pin),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zrušiť úlohy.`)}
        />;
    }

}

export default AssignmentRemove;