import React from 'react';
import {Trans, t} from '@lingui/macro';
import { Assignment } from '../../constants/propTypesDefinitions';
import { formatQuantity } from '../../lib/formatting';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';

/**
 * @dusan
 */

 class BonusExplanation extends React.PureComponent {
    static propTypes = {
        assignment: Assignment.isRequired,
    };

    render() {
        const {assignment, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const a = assignment != null ? assignment : {};
        const currency = settings.default_currency;
        return <div>
            <div>
                <Trans>koef:</Trans>
                <span className="ml-2">
                    { formatQuantity(a.bonus_ratio, a.units != currency ? currency + (a.units != null ? '/' + a.units : '') : null) 
                        + ' x "' + a.task + '"'
                        + ( a.bonus_base != 0 ? ' - ' + formatQuantity(a.bonus_base, currency) : '')
                    }
                </span>
            </div>
            <div>
                <Trans>min:</Trans>
                <span className="ml-2">{formatQuantity(a.bonus_min, currency)}</span>
            </div>
            <div>
                <Trans>max:</Trans>
                <span className="ml-2">{formatQuantity(a.bonus_max, currency)}</span>
            </div>
            { a.master_tasks != null && a.master_tasks.length > 0 ?
                <div>
                    <Trans>podmienky:</Trans>
                    <div className='ml-3'>
                        {a.master_tasks.map((mt, idx) => {
                            return <div key={idx}>
                                {'"' + mt.master_task + '" > ' + (Number(mt.critical_level) * 100) + '% '}
                                <Trans>z plánu</Trans>
                            </div>
                        })}
                    </div>
                </div>
                : null
            }
        </div>;
    }
 };

 export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(BonusExplanation);