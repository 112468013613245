import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Input, InputNumber as AntdInputNumber} from 'antd';
import InputNumber from '../general/InputNumber';
import {createFetchAssignmentAdd, createFetchAssignmentEdit} from '../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../constants/form';
import { Assignments } from '../../constants/propTypesDefinitions';
import TaskSelect from './TaskSelect';
import EmployeesSelect from '../project/EmployeesSelect';
import MonthSelect from '../general/MonthSelect';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import { arrayUnique } from '../../lib/array';
import InputQuantity from '../project/InputQuantity';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import InputPrice from '../project/InputPrice';
const FormItem = Form.Item;
const TextArea = Input.TextArea;

/**
 * @dusan
 */

 function oneOrNone(src, key) 
 {
    if(src == null)
        return undefined;

    if(!Array.isArray(src))
        return src;
    
    const vals = arrayUnique(src.map(a => a[key]));
    if(vals.length == 1)
        return vals[0];
    else
        return undefined;
}

class AssignmentForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            isEdit: PropTypes.bool,
            userId: PropTypes.number,
            taskId: PropTypes.number,
            year: PropTypes.number,
            month: PropTypes.number,
            assignments: Assignments,
            pin: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            taskId: undefined,
        };
    }

    handleTaskChange = (newTaskId) => {
        this.setState({taskId: newTaskId});
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values: {isEdit, assignments, pin}} = this.props;
        const {getFieldsValue} = this.props.form;

        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                const formValues = getFieldsValue(null, meta => meta.touched);
                this.setState({loading: true});
                const year = formValues.year;
                const months = formValues.month != null ? 
                    (Array.isArray(formValues.month) ? formValues.month : [formValues.month]) : [];

                const at = months.map(m => String(year) + '-' + String(m).padStart(2, '0'));
                const fetchFunc = isEdit ? createFetchAssignmentEdit() : createFetchAssignmentAdd();
                const assignmentIds = assignments != null && Array.isArray(assignments) ? assignments.map(a => a.id) : undefined;
                const valuesChecked = merge(formValues, {at: at, id: assignmentIds, pin: pin});

                fetchFunc(valuesChecked).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };


    

    render() {
        const {loading, taskId} = this.state;
        const {values: {isEdit, userId, taskId: initTaskId, year, month, assignments}, onClose, 
            [GLOBAL_DATA.TASKS]: tasks, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const {getFieldDecorator} = this.props.form;
        
        const months = assignments != null && assignments.length > 0 ? arrayUnique(assignments.map(a => a.mon)) : month;
        const userIds = assignments != null && assignments.length > 0 ? arrayUnique(assignments.map(a => a.id_user)) : userId;
        const taskIds = assignments != null && assignments.length > 0 ? arrayUnique(assignments.map(a => a.id_task)) : [(initTaskId || taskId)];
        const isEditOrCopy = isEdit || (assignments != null && assignments.length > 0);

        const placeholder = isEditOrCopy ? t`bez zmeny` : null;
        const task = taskIds.length == 1 ? tasks.find(t => t.id == taskIds[0]) : null;
        const taskChecked = task != null ? task : {};

        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zamestnanec</Trans>}
            >
                {getFieldDecorator('id_user', {
                    rules: isEdit ? undefined : [mandatoryRule], 
                    initialValue: userIds
                })(
                    <EmployeesSelect 
                        disabled={isEdit}
                        isMulti={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ úlohy</Trans>}
            >
                {getFieldDecorator('id_task', {
                        rules: isEditOrCopy ? undefined : [mandatoryRule], 
                        initialValue: taskIds,
                        onChange: this.handleTaskChange
                })(
                    <TaskSelect 
                        disabled={isEditOrCopy}
                        placeholder={placeholder}
                        isMulti={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Rok</Trans>}
            >
                {getFieldDecorator('year', {
                    rules: isEdit ? undefined : [mandatoryRule], 
                    initialValue: oneOrNone(assignments, 'year') || year
                })(
                    <AntdInputNumber 
                        min={2020}
                        disabled={isEdit}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Mesiac</Trans>}
            >
                {getFieldDecorator('month', {
                    rules: isEdit ? undefined : [mandatoryRule], 
                    initialValue: months
                })(
                    <MonthSelect 
                        disabled={isEdit}
                        selectAllOption={<Trans>Všetky mesiace</Trans>}
                        isMulti={true}
                    />
                )}
            </FormItem>
            { task == null || task.is_binary == 0 ? 
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Plán</Trans>}
                >
                    {getFieldDecorator('target', {
                        rules: isEditOrCopy ? undefined : [mandatoryRule], 
                        initialValue: oneOrNone(assignments, 'target')
                    })(
                        <InputQuantity 
                            placeholder={placeholder}
                            unit={taskChecked.units}
                        />
                    )}
                </FormItem> : null
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Koeficient odmeny</Trans>}
            >
                {getFieldDecorator('bonus_ratio', {initialValue: oneOrNone(assignments, 'bonus_ratio')})(
                    <InputQuantity 
                        placeholder={placeholder}
                        unit={settings.default_currency + (taskChecked.units != null ? ' / ' + taskChecked.units : '')}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Základ odmeny</Trans>}
            >
                {getFieldDecorator('bonus_base', {initialValue: oneOrNone(assignments, 'bonus_base')})(
                    <InputPrice 
                        placeholder={placeholder}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Min. odmena</Trans>}
            >
                {getFieldDecorator('bonus_min', {initialValue: oneOrNone(assignments, 'bonus_min')})(
                    <InputPrice 
                        placeholder={placeholder}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Max. odmena</Trans>}
            >
                {getFieldDecorator('bonus_max', {initialValue: oneOrNone(assignments, 'bonus_max')})(
                    <InputPrice 
                        placeholder={placeholder}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámky</Trans>}
            >
                {getFieldDecorator('notices', {initialValue: oneOrNone(assignments, 'notices')})(
                    <TextArea
                        placeholder={placeholder}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Pridať</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(
    withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.TASKS])(AssignmentForm)
    )
);