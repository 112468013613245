import { notification } from 'antd';
import React from 'react';
import { ERROR_CODES } from '../../constants/errorCodes';
import InputPin from '../general/InputPin';
import {Trans, t} from '@lingui/macro';

/**
 * Class that shows PIN input
 * @dusan
 */

const pinProtectedPageHOC = (Component) => {
    return class extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                pin: null
            };
        }

        componentDidMount() {
            this.setState({pin: null});
        }

        setPin = (newPin) => {
            this.setState({pin: newPin});
        }

        onPinFailure = (error) => {
            if(error != null && error.code == ERROR_CODES.USER_INVALID_PIN)
            {
                notification['error']({
                    message: t`Nesprávny PIN`,
                    duration: 10,
                });
                this.setState({pin: null});
            }
        }

        render() {
            const {pin} = this.state;
            if(pin == null)
            {
                return <div className="full-size-width full-size-height d-flex justify-content-center">
                    <div>
                        <div className="d-flex align-items-center pin-prompt">
                            <span className="mr-2">PIN:</span>
                            <InputPin
                                onChange={this.setPin}
                            />
                        </div>
                        <div>
                            <Trans>PIN si môžete zmeniť v sekcii Môj účet</Trans>
                        </div>
                    </div>
                </div>;
            }
            else
            {
                return <Component
                    {...this.props}
                    pin={pin}
                    onPinFailure={this.onPinFailure}
                />;
            }
        }
    }
};

export default pinProtectedPageHOC;