import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';

/**
 * Component that enables 4-input number. Input fires onChange immediately after 4-th digit is typed
 *
 * @dusan
 */

class InputPin extends React.Component {
    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            val: ''
        };
    }

    componentDidMount() {
        this.setState({val: ''});
    }

    onKeyDown = (ev) => {
        ev.preventDefault();
        
        if(ev.key < '0' || ev.key > '9')
            return;

        const {onChange} = this.props;
        const {val} = this.state;
        const newVal = val + ev.key;
        this.setState({val: newVal});

        if(newVal != null && newVal.length == 4)
            onChange(newVal);
    }
    

    render() {
        const {val} = this.state;
        const {onChange, ...props} = this.props;
        return <Input
            {...props}
            autoComplete="off"
            minLength={4}
            maxLength={4}
            onKeyDown={this.onKeyDown}
            value={"\u2022".repeat(val.length)}
            autoFocus={true}
        />;
    }

}

export default InputPin;