import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { Assignments } from '../../constants/propTypesDefinitions';
import AssignmentForm from './AssignmentForm';

/**
 * @dusan
 */

class AssignmentEdit extends React.PureComponent {
    static propTypes = {
        assignments: Assignments.isRequired,
        pin: PropTypes.string.isRequired,
        onFinish: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {assignments, pin, onFinish} = this.props;
        const disabled = assignments == null || assignments.length == 0;
        return <FormModal
            openNode={
                <Button className="mx-1" icon="edit" disabled={disabled}>
                    <span className="ml-2"><Trans>Upraviť úlohy</Trans></span>
                </Button>
            }
            disabled={disabled}
            values={{isEdit: true, assignments: assignments, pin: pin}}
            onFinishSuccessful={onFinish}
            title={<Trans>Úprava zadania</Trans>}
            Form={AssignmentForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť zadanie.`)}
        />;
    }

}

export default AssignmentEdit;