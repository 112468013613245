import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../fetch/generalFailedPC';
import FormModal from '../fetch/FormModal';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {Assignments} from '../../constants/propTypesDefinitions';
import {createFetchAssignmentReopen, createFetchAssignmentSign} from '../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class AssignmentSign extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        assignments: Assignments.isRequired,
        pin: PropTypes.string.isRequired,
    };

    render() {
        const {assignments, pin, onFinish} = this.props;
        const assignmentIds = assignments != null ? assignments.map(a => a.id) : [];
        const disabled = assignmentIds.length == 0;
        const unlock = assignments != null && assignments.length > 0 && assignments.filter(a => a.signed_at == null).length == 0;
        
        return <FormModal
            openNode={
                <Button 
                    className="mx-1"
                    icon={unlock ? 'unlock' : 'lock'}
                    disabled={disabled}
                >
                    <span className="ml-2">
                        {unlock ? <Trans>Odomnkúť úlohy</Trans> : <Trans>Zamknúť úlohy</Trans>}
                    </span>
                </Button>
            }
            disabled={disabled}
            values={{}}
            onFinishSuccessful={onFinish}
            title={<Trans>Zrušenie úloh</Trans>}
            Form={generalNoParameterFormPC(
                unlock ? <Trans>Naozaj chcete odomknúť všetky vybrané úlohy?</Trans> : <Trans>Naozaj chcete zamknúť všetky vybrané úlohy?</Trans>,
                unlock ? createFetchAssignmentReopen(assignmentIds, pin) : createFetchAssignmentSign(assignmentIds, pin),
            )}
            Response={null}
            Failed={generalFailedPC(unlock ? <Trans>Nepodarilo sa odomknúť úlohy.</Trans> : <Trans>Nepodarilo sa zamknúť úlohy.</Trans>)}
        />;
    }

}

export default AssignmentSign;