import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../constants/Rights';
import AssignmentForm from './AssignmentForm';
import { Assignments } from '../../constants/propTypesDefinitions';
import Tooltip from '../general/Tooltip';

/**
 * @dusan
 */

class AssignmentAdd extends React.PureComponent {
    static propTypes = {
        userId: PropTypes.number,
        taskId: PropTypes.number,
        year: PropTypes.number,
        month: PropTypes.number,
        size: PropTypes.string,
        assignments: Assignments,
        pin: PropTypes.string.isRequired,
        onFinish: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {onFinish, userId, taskId, year, month, assignments, pin, size} = this.props;
        const isCopy = assignments != null && assignments.length > 0;
        return <RightsWrapper from={RIGHTS.MANAGER}>
            <FormModal
                openNode={
                    <Tooltip title={isCopy ? <Trans>Duplikovať</Trans> : <Trans>Zadať novú úlohu</Trans>}>
                        <Button 
                            className="mx-1" 
                            type={isCopy ? null : "primary"}
                            size={size}
                            icon={isCopy ? "copy" : "plus"}
                        >
                            { size != 'small' ? 
                                <span className="ml-2">
                                    {isCopy ? <Trans>Kopírovať úlohy</Trans> : <Trans>Zadať novú úlohu</Trans>}
                                </span> : 
                                null
                            }
                        </Button>
                    </Tooltip>
                }
                values={{
                    isEdit: false, 
                    userId: userId,
                    taskId: taskId, 
                    year: year, 
                    month: month,
                    assignments: assignments,
                    pin: pin
                }}
                onFinishSuccessful={onFinish}
                title={<Trans>Zadať úlohu</Trans>}
                Form={AssignmentForm}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa zadať úlohu`)}
            />
        </RightsWrapper>;
    }
}

export default AssignmentAdd;