import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../fetch/generalFailedPC';
import {Assignments} from '../../constants/propTypesDefinitions';
import {createFetchAssignmentReload} from '../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';
import ButtonFetch from '../fetch/ButtonFetch';

/**
 * @dusan
 */

class AssignmentReload extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        assignments: Assignments.isRequired,
        pin: PropTypes.string.isRequired,
        size: PropTypes.string,
    };

    render() {
        const {assignments, pin, onFinish, size} = this.props;
        const assignmentIds = assignments != null ? assignments.map(a => a.id) : [];
        const disabled = assignmentIds.length == 0;
        return <ButtonFetch 
            className="mx-1" 
            icon="reload" 
            disabled={disabled} 
            size={size}
            values={{}}
            fetchFunction={createFetchAssignmentReload(assignmentIds, pin)}
            onFinish={onFinish}
            Failed={generalFailedPC(t`Nepodarilo sa aktualizovať výsledky úloh.`)}
        >
            { size != 'small' ? 
                <span className="ml-2"><Trans>Aktualizovať výsledky</Trans></span>
                : null
            }
        </ButtonFetch>;
    }

}

export default AssignmentReload;