import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import TaskForm from './TaskForm';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../constants/Rights';
import { Task } from '../../constants/propTypesDefinitions';
import Tooltip from '../general/Tooltip';

/**
 * @dusan
 */

class TaskAdd extends React.PureComponent {
    static propTypes = {
        task: Task,
        size: PropTypes.string,
        onFinish: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {task, size, onFinish} = this.props;
        return <RightsWrapper from={RIGHTS.MANAGER}>
            <FormModal
                openNode={
                    <Tooltip title={<Trans>Nový typ úlohy</Trans>}>
                        <Button icon={task != null ? "copy" : "plus"} size={size}/>
                    </Tooltip>
                }
                values={{isEdit: false, task: task}}
                onFinishSuccessful={onFinish}
                title={<Trans>Nový typ úlohy</Trans>}
                Form={TaskForm}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť úlohu`)}
            />
        </RightsWrapper>;
    }
}

export default TaskAdd;