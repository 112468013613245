import React from 'react';
import {Trans, t} from '@lingui/macro';
import PropTypes from 'prop-types';
import { Wages } from '../../constants/propTypesDefinitions';
import Table from '../general/Table';
import Link from '../navigation/Link';
import { QUERY_PARAMS } from '../../constants/navigation';
import Price from '../general/Price';
import {GLOBAL_DATA} from '../../constants/globalData';
import { createFetchWageEdit } from '../../backend/apiCalls';
import { merge } from '../../lib/object';
import InputPrice from '../project/InputPrice';
import withDataHOC from '../dataProvider/withDataHOC';
import WageExport from './WageExport';
import WageImport from './WageImport';
import { notification } from 'antd';

/**
 * @dusan
 */

 class WagesTable extends React.PureComponent {
    static propTypes = {
        wages: Wages.isRequired,
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        reload: PropTypes.func.isRequired,
        pin: PropTypes.string.isRequired,
    };

    exportTransform = (categories) => (data) => {
        return data.map(emp => {
            let obj = {
                [t`Zamestnanec`]: emp.user,
            };
            
            categories.forEach((cat) => {
                const bonusObj = Array.isArray(emp.bonuses) ? emp.bonuses.find(b => b.category == cat) : null;
                const bonus = bonusObj != null ? bonusObj.amount : null;
                obj[cat ?? t`Ostatné odmeny`] = bonus;
            });

            obj[t`Odmeny spolu`] = emp.sum_bonus;
            obj[t`Brutto`] = emp.brutto;
            obj[t`Netto`] = emp.netto;
            obj[t`Cena práce`] = emp.total;

            return obj;            
        });
    }

    render() {
        const {wages, pin, year, month, reload} = this.props;
        const employeesChecked = wages != null && Array.isArray(wages.employees) ? wages.employees : [];
        const summaryChecked = wages != null && wages.summary != null ? wages.summary : {};
        const categories = Array.isArray(summaryChecked.bonuses) ? summaryChecked.bonuses.map(b => b.category) : [];
        
        let colDefs = [
            {
                headerCell: <Trans>Zamestnanec</Trans>,
                className: 'assignments-sum-table-col-user',
            }
        ];

        categories.forEach((cat) => {
            colDefs.push({
                headerCell: cat || <Trans>Ostatné odmeny</Trans>,
                className: 'assignments-sum-table-col'
            });
        });

        colDefs.push({
            headerCell: <span className="text-right"><Trans>Spolu</Trans></span>,
            className: 'assignments-sum-table-col-sum',
        });

        colDefs.push({
            headerCell: <span className="text-right"><Trans>Brutto</Trans></span>,
            className: 'assignments-sum-table-col-sum',
        });

        colDefs.push({
            headerCell: <span className="text-right"><Trans>Netto</Trans></span>,
            className: 'assignments-sum-table-col-sum',
        });

        colDefs.push({
            headerCell: <span className="text-right">
                <Trans>Cena práce</Trans>
                <WageExport 
                    size="small" 
                    className="ml-2"
                    year={year}
                    month={month}
                    pin={pin}
                    onFinish={reload}
                />
                <WageImport 
                    size="small" 
                    className="ml-2"
                    year={year}
                    month={month}
                    pin={pin}
                    onFinish={reload}
                />
            </span>,
            className: 'assignments-sum-table-col-sum',
        });

        return <Table
            className="assignments-table"
            BodyRow={WagesRowWrapped}
            data={employeesChecked}
            categories={categories}
            year={year}
            month={month}
            pin={pin}
            reload={reload}
            colDefs={colDefs}
            exportTransform={this.exportTransform(categories)}
            footerRow={
                <SummaryRow
                    data={summaryChecked}
                />
            }
        />;
    }
 };

export default WagesTable;

class WagesRow extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object.isRequired,
        pin: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired,
        reload: PropTypes.func.isRequired,
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    editWage = (queryParams) => {
        const {data, year, month, reload, pin} = this.props;
        if(year == null || month == null)
            return;

        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchWageEdit(),
            merge({id_user: data.id_user, yr: year, mon: month, pin: pin}, queryParams),
            null,
            reload,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    render() {
        const {data, categories, year, month} = this.props;
        const isEditable = year != null && month != null;
        return <tr className={data.is_signed ? "light-green-background" : ""}>
            <td>
                <Link
                    className="px-2 text-dark font-weight-bold"
                    to={null}
                    queryParams={{[QUERY_PARAMS.ASSIGNMENTS_ID_USER]: data.id_user}}
                    title={<Trans>Detail zamestnanca</Trans>}
                >
                    {data.user}
                </Link>
            </td>
            { categories.map((cat, key) => {
                const bonusObj = Array.isArray(data.bonuses) ? data.bonuses.find(b => b.category == cat) : null;
                const bonus = bonusObj != null ? bonusObj.amount : null;
                return <td key={key} className="text-right">
                    <Price price={bonus}/>
                </td>;
            })}
            <td className="text-right font-weight-bold">
                <Price price={data.sum_bonus}/>
            </td>
            <td className="text-right">
                {isEditable ? 
                    <InputPrice
                        value={data.brutto}
                        size="small"
                        className="text-right"
                        onChange={(newVal) => this.editWage({brutto: newVal})}
                    />
                    :
                    <Price price={data.brutto}/>
                }
            </td>
            <td className="text-right">
                { isEditable ?
                    <InputPrice
                        value={data.netto}
                        size="small"
                        className="text-right"
                        onChange={(newVal) => this.editWage({netto: newVal})}
                    />
                    :
                    <Price price={data.netto}/>
                }
            </td>
            <td className="text-right font-weight-bold">
                { isEditable ?
                    <InputPrice
                        value={data.total}
                        size="small"
                        className="text-right font-weight-bold"
                        onChange={(newVal) => this.editWage({total: newVal})}
                    />
                    :
                    <Price price={data.total}/>
                }
            </td>
        </tr>;
    }
};

const WagesRowWrapped = withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(WagesRow);

class SummaryRow extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const {data} = this.props;
        return <tr>
            <td className="font-weigth-bold"><Trans>Spolu:</Trans></td>
            { Array.isArray(data.bonuses) ? data.bonuses.map((sumObj, idx) => {
                return <td key={idx} className="text-right font-weight-bold">
                    <Price price={sumObj != null ? sumObj.amount : null}/>
                </td>;
            }) : null}
            <td className="text-right font-weight-bold">
                <Price price={data.sum_bonus}/>
            </td>
            <td className="text-right">
                <Price price={data.brutto}/>
            </td>
            <td className="text-right">
                <Price price={data.netto}/>
            </td>
            <td className="text-right font-weight-bold">
                <Price price={data.total}/>
            </td>
        </tr>;
    }
};