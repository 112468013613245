import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {Trans} from '@lingui/macro';
import Tooltip from '../general/Tooltip';
import { EXPORT_TARGET } from '../../constants/apiEndpoints';
/**
 * @dusan
 */

class WageExport extends React.PureComponent {
    static propTypes = {
        year: PropTypes.number.isRequired,
        month: PropTypes.number,
        pin: PropTypes.string.isRequired,
        onFinish: PropTypes.func.isRequired,
    };

    render() {
        const {year, month, pin, ...props} = this.props;
        let url = EXPORT_TARGET + `?export_type=wage/export&yr=${year}&pin=${pin}`;
        if(month != null)
            url += `&mon=${month}`;

        return <a href={url}>
            <Tooltip title={<Trans>Export</Trans>}>
                <Button
                    icon="download"
                    {...props}
                />
            </Tooltip>
        </a>;
    }

}

export default WageExport;