import React from 'react';
import {Trans, t} from '@lingui/macro';
import PropTypes from 'prop-types';
import { Assignment } from '../../constants/propTypesDefinitions';
import InputNumber from '../general/InputNumber';
import { GLOBAL_DATA } from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import { createFetchAssignmentEdit } from '../../backend/apiCalls';
import { Checkbox, notification } from 'antd';
import { getInputOnChangeEventChecked } from '../../lib/project';
import { formatQuantity, formatString } from '../../lib/formatting';
import InputTextArea from '../general/InputTextArea';
import Tooltip from '../general/Tooltip';
import BonusExplanation from './BonusExplanation';
import InputQuantity from '../project/InputQuantity';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import InputPrice from '../project/InputPrice';

/**
 * @dusan
 */

 class AssignmentCell extends React.PureComponent {
    static propTypes = {
        data: Assignment,
        dataKey: PropTypes.string.isRequired,
        reload: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        selectedAssignmentIds: PropTypes.arrayOf(PropTypes.number),
        pin: PropTypes.string.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    editItem = (newVal) => {
        const {data, dataKey, reload, pin} = this.props;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchAssignmentEdit(),
            {id: data.id, pin: pin, [dataKey]: newVal},
            null,
            reload,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    render() {
        const {data, dataKey, selectedAssignmentIds, onSelect, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const isSelected = selectedAssignmentIds != null && data != null && selectedAssignmentIds.includes(data.id);

        if(data == null)
            return null;

        switch(dataKey)
        {
            case 'target':
                return data.signed_at == null ?
                    <InputQuantity
                        className="full-size-width text-right"
                        size="small"
                        value={data.target}
                        onChange={this.editItem}
                        units={data.units}
                    />
                    :
                    formatQuantity(data.target, data.units);

            case 'result':
                return data.is_binary ? 
                    <div>
                        { data.signed_at == null ?
                            <Checkbox 
                                checked={data.result == 1}
                                onChange={(ev) => {
                                    const val = getInputOnChangeEventChecked(ev) ? 1 : 0;
                                    this.editItem(val);
                                }}
                            >
                                <Trans>splnené</Trans>
                            </Checkbox>
                            :
                            <span className={data.result ? 'color-green' : 'color-red'}>
                                {data.result ? <Trans>splnené</Trans> : <Trans>nesplnené</Trans>}
                            </span>
                        }
                    </div>
                    :
                    <div> 
                        { data.signed_at == null ?
                            <InputQuantity
                                className="full-size-width text-right"
                                size="small"
                                value={data.result}
                                onChange={this.editItem}
                                unit={data.units}
                            />
                            :
                            <span className={Number(data.dispo || 0) >= Number(data.target) ? 'color-green' : 'color-red'}>
                                {formatQuantity(data.result, data.units)}
                            </span>
                        }
                        <div className="table-subdata">
                            <Trans>dispo:</Trans>
                            <span className={(Number(data.dispo || 0) >= Number(data.target) ? 'color-green' : 'color-red') + ' ml-2'}>{formatQuantity(data.dispo, data.units)}</span>
                        </div>
                    </div>;

            case 'saldo':
                return <span className={data.saldo < 0 ? "color-red font-weight-bold" : ""}>
                    {formatQuantity(data.saldo, data.units)}
                </span>;
            
            case 'bonus':
                return <Tooltip 
                    title={<BonusExplanation assignment={data}/>}
                >
                    <div>
                        { data.signed_at == null && data.auto_bonus != 1 ?
                            <InputPrice
                                className="full-size-width text-right"
                                size="small"
                                value={data.bonus}
                                onChange={this.editItem}
                                min={data.bonus_min}
                                max={data.bonus_max}
                            />
                            :
                            formatQuantity(data.bonus, settings.default_currency)
                        }
                        <div className="table-subdata">
                            <Trans>nárok:</Trans>
                            <span className="ml-2">{formatQuantity(data.bonus_available, settings.default_currency)}</span>
                        </div>
                    </div>
                </Tooltip>;

            case 'notices':
                return data.signed_at == null ?
                    <InputTextArea
                        className="full-size-width"
                        size="small"
                        value={data.notices}
                        onChange={this.editItem}
                    />
                    :
                    formatString(data.notices);

            case 'actions':
                return <Checkbox
                        checked={isSelected}
                        onChange={(e) => {
                            const val = getInputOnChangeEventChecked(e);
                            if(val)
                            {
                                const newIds = selectedAssignmentIds != null && !isSelected ? [...selectedAssignmentIds, data.id] : [];
                                onSelect(newIds);
                            }
                            else
                            {
                                const newIds = selectedAssignmentIds != null ? selectedAssignmentIds.filter(id => id != data.id) : [];
                                onSelect(newIds);
                            }
                        }}
                    >
                    <Trans>vybrať</Trans>
                </Checkbox>;

            default:
                return null;
        }
    }
 };

 export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
     withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(AssignmentCell)
 );