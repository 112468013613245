import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import Tooltip from '../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { Task } from '../../constants/propTypesDefinitions';
import TaskForm from './TaskForm';

/**
 * @dusan
 */

class TaskEdit extends React.PureComponent {
    static propTypes = {
        task: Task.isRequired,
        size: PropTypes.string,
        onFinish: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {task, size, onFinish} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Upraviť typ úlohy</Trans>}>
                    <Button icon="edit" size={size}/>
                </Tooltip>
            }
            values={{isEdit: true, task: task}}
            onFinishSuccessful={onFinish}
            title={<Trans>Úprava typu úlohy</Trans>}
            Form={TaskForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť typ úlohy`)}
        />;
    }

}

export default TaskEdit;